<template>
    <div class="admin_page_body">
        <div class="admin_page_item" style="padding: 30px 60px">
            <div class="data_group mt0">
                <el-button type="primary" size="small" @click="toRead">全部已读</el-button>
            </div>

            <div class="data_table mt24" style="padding-right: 0">
                <div class="content" v-loading="isRequestLoading" style="min-height: 308px; padding: 0; margin: 0; border: none">
                    <ul>
                        <li v-for="(item, index) in listData" :key="index" style="border-bottom: 1px dashed #ccc;">
                            <a href="javascript:void(0)" @click="$router.push({path: '/admin/notice/detail', query: {id: item.id}})" style="align-items: center; display: flex; height: 50px; justify-content: space-between">
                                <div class="title flexRow" style="align-items: center; line-height: 16px; display: flex; font-size: 14px; color: #333;"><div style="width: 22px; height: 16px" v-if="item.isRead"></div><div style="width: 4px; height: 4px; background: #5DAD03; border-radius: 50%; display: inline-block; margin-right: 18px" v-else></div>{{item.title}}</div>
                                <span v-if="item.createAt" class="time" style="color: #333">{{item.createAt.split('T')[0]}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {imageURL} from "../../../utils/config";
  import {getAdminNotice, getAdminNoticeLength, postAdminNoticeRead} from "../../../api/admin/notice";
  const pagination = () => import('../../../components/pagination/admin')
  export default {
    name: "adminNotice",
    data () {
      return {
        listQuery: {
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        imageURL: imageURL
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getAdminNotice(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toRead () {
        this.$confirm('此操作将设置公告为全部已读, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postAdminNoticeRead().then(res => {
            if (res.code === 0) {
              this.$message({
                message: '全部已读成功',
                type: 'success',
                showClose: true,
              });
              this.getNoticeLength();
              this.getList();
            }
          })
        }).catch(() => {});
      },
      getNoticeLength () {
        getAdminNoticeLength().then(res => {
          if (res.code === 0) {
            this.$store.commit('changeNoticeLength', res.data)
          }
        })
      }
    },
    components: {pagination}
  }
</script>

<style scoped lang="scss">
    .data_table a{
      &:hover{
          div, span{
              color: #5DAD03 !important;
          }
      }
    }
</style>
